import React from "react";
import PropTypes from "prop-types";
import LinkButton from "../common/LinkButton";

const HowWeWork = ({ t, link }) => (
  <section className="section HowWeWork">
    <div className="container HowWeWork__container">
      <div className="HowWeWork__left">
        <h2 className="title">{t.title}</h2>
        <p className="HowWeWork__left__description">{t.description}</p>
        <LinkButton to={link}>{t.btn}</LinkButton>
      </div>

      <div className="HowWeWork__list">
        {t.list.map((elem, index) => (
          <div key={index} className="HowWeWork__item">
            <span className="HowWeWork__item__nr">0{index + 1}</span>
            <div>
              <h4 className="HowWeWork__item__title">{elem.title}</h4>
              <p className="HowWeWork__item__description">{elem.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

HowWeWork.propTypes = {
  t: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired
};

export default HowWeWork;
